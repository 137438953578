@import './layout.scss';
@import 'pricing';

html{

  @media (max-width: 1024px) {
    .intercom-lightweight-app-launcher {
    bottom: 72px !important;
    }

    /*localhost*/
    .intercom-dfosxs {
    bottom: 72px !important;
    }

    iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
    }
  }
}


